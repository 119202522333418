import { Controller } from '@hotwired/stimulus'
import { sendAnalyticsEvent } from '@envato/gtm-analytics'
import { googleAnalytics3Event } from 'lib/googleAnalytics3'

export default class extends Controller {
  static values = {
    ga3EventAction: String,
    ga3EventCategory: String,
    url: String,
    itemId: String,
    itemResolution: String,
    downloadContext: String,
    elementsItemId: String
  }

  connect () {
    this.runDownloadProcess()
  }

  runDownloadProcess () {
    googleAnalytics3Event({
      eventAction: this.ga3EventActionValue,
      eventCategory: this.ga3EventCategoryValue,
      eventLabel: this.itemIdValue
    })

    sendAnalyticsEvent({
      eventName: 'download',
      itemId: this.itemIdValue,
      itemResolution: this.itemResolutionValue,
      context: this.downloadContextValue,
      elementsItemId: this.elementsItemIdValue,
      customConfig: { skipNormalizationForAttributes: ['elementsItemId'] },
      callback: () => {
        this.downloadTheFile()
      }
    })
  }

  downloadTheFile () {
    // This is a function we call to force the browser to download a file.
    // It's in a timeout to allow the browser to render the modal images correctly
    setTimeout(() => {
      window.location.href = this.urlValue
    }, 500)
  }

  getGlobalModalController () {
    return this.application.controllers.find(controller => controller.context.identifier === 'modal')
  }

  closeModal () {
    // Reach out to the existing other global modal controller
    const modalController = this.getGlobalModalController()

    // Hide modal from the screen
    modalController.hideModal()
  }
}
