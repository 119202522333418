import { Controller } from '@hotwired/stimulus'
import { googleAnalytics3Event } from 'lib/googleAnalytics3'

export default class extends Controller {
  static values = {
    applicable: Boolean, // This tells us if the user has changed filters, so we can show the apply button
    baseUri: String, // This is the URL we'll use to append our search params
    facets: Array, // This contains a JSON representation of all our filter options, we'll use this to craft our filter URL on apply button push
    filtersApplied: Boolean // If filters have already been applied at time of page load, so we can show the "clear" button
  }

  applyFilters () {
    this.logFilterApplyButtonPress(() => {
      window.location.assign(this.buildFilterUri())
    })
  }

  clearFilters () {
    window.location.assign(this.baseUriValue)
  }

  changeFilter () {
    this.applicableValue = true
  }

  buildFilterUri () {
    const facetUriParams = [] // Using [] as URLSearchParams doesn't like multiple tags[] values it seems
    for (const facet of this.facetsValue) {
      // Build the URL parameter key. This will be  facet[]= or facet= based on the multi_select option
      const facetUrlSlug = facet.multi_select ? `${facet.facet}[]` : facet.facet
      for (const possibleFacetValue of facet.options) {
        // check if this option is ticked in the dom
        const facetInDom = document.getElementById(`facet-${facet.facet}-${possibleFacetValue.slug}`)
        const isFacetTicked = facetInDom.checked
        // If we have a default option (e.g. orientation=all) we can skip adding that to the query param
        const isFacetTheDefaultOption = possibleFacetValue.default
        if (isFacetTicked && !isFacetTheDefaultOption) {
          facetUriParams.push({
            key: facetUrlSlug,
            value: facetInDom.dataset.facet
          })
        }
      }
    }

    return this.baseUriValue + '?' + facetUriParams.map(param => `${param.key}=${encodeURIComponent(param.value)}`).join('&')
  }

  logFilterApplyButtonPress (callback) {
    googleAnalytics3Event({
      eventAction: 'click',
      eventCategory: 'Search Filter Apply',
      callback
    })
  }
}
