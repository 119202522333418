import { Controller } from '@hotwired/stimulus'
import { googleAnalytics3Event } from 'lib/googleAnalytics3'

export default class extends Controller {
  static values = {
    campaign: String,
    uri: String
  }

  setOriginalSource ({ detail: { originalSource } }) {
    const anchor = this.element
    const params = new URLSearchParams(anchor.search)

    if (originalSource) {
      params.set('utm_content', originalSource)
    } else {
      params.delete('utm_content')
    }

    anchor.search = params.toString()
  }

  logClickEvent () {
    googleAnalytics3Event({
      eventAction: 'Elements Click',
      eventCategory: this.campaignValue,
      eventLabel: this.uriValue
    })
  }
}
