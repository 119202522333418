import { localStorageSet, localStorageGet } from 'lib/localStorageWrapper'

const ELEMENTS_TOAST_BANNER_DISMISSAL_KEY = 'elements-toast-banner-dismissal'
const THIRTY_DAYS_IN_MILLISECONDS = 2592000000

export const shouldDisplayElementsToastBanner = () => {
  let timestampWhenBannerDismissed = localStorageGet(ELEMENTS_TOAST_BANNER_DISMISSAL_KEY)

  timestampWhenBannerDismissed = parseInt(timestampWhenBannerDismissed)
  const isBannerNeverBeenDismissed = isNaN(timestampWhenBannerDismissed)

  const timestampForThirtyDaysAgo = Date.now() - THIRTY_DAYS_IN_MILLISECONDS
  const isBannerDismissedMoreThanThirtyDaysAgo = timestampWhenBannerDismissed < timestampForThirtyDaysAgo

  return isBannerNeverBeenDismissed || isBannerDismissedMoreThanThirtyDaysAgo
}

export const markElementsToastBannerDismissed = () => {
  localStorageSet(ELEMENTS_TOAST_BANNER_DISMISSAL_KEY, Date.now().toString())
}
