import { Controller } from 'stimulus'
import { googleAnalytics3Event } from 'lib/googleAnalytics3'

export default class extends Controller {
  static targets = ['video']
  static values = {
    loaded: Boolean,
    itemId: String,
    itemType: String,
    playing: Boolean
  }

  mouseOver () {
    this.playVideo()
  }

  mouseOut () {
    this.pauseVideo()
  }

  playVideo () {
    if (this.hasVideoTarget) {
      const videoPromise = this.videoTarget.play()
      if (typeof videoPromise !== 'undefined') {
        videoPromise.then(() => {
          if (!this.loadedValue) {
            this.loadedValue = true
            this.logVideoPreviewEvent()
          }
          this.playingValue = true
        }).catch(() => {})
      }
    }
  }

  pauseVideo () {
    if (this.hasVideoTarget && this.playingValue) {
      // Only pause if video is actually playing
      // Re: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      this.videoTarget.pause()
    }
    this.playingValue = false
  }

  logVideoPreviewEvent () {
    googleAnalytics3Event({
      eventAction: 'hover',
      eventCategory: 'Elements Video Preview',
      eventLabel: this.itemIdValue,
      nonInteraction: true
    })
  }
}
