import { Controller } from '@hotwired/stimulus'
import { googleAnalytics3Event } from 'lib/googleAnalytics3'

export default class extends Controller {
  static values = {
    action: String,
    category: String,
    label: String,
    value: String,
    nonInteraction: String
  }

  connect () {
    googleAnalytics3Event({
      eventAction: this.actionValue,
      eventCategory: this.categoryValue,
      eventLabel: this.labelValue,
      eventValue: this.valueValue,
      nonInteraction: this.nonInteractionValue
    })
  }
}
