import { Controller } from 'stimulus'
import { googleAnalytics3Event } from 'lib/googleAnalytics3'

export default class extends Controller {
  static targets = ['video']
  static values = {
    loaded: Boolean,
    itemId: String,
    itemType: String,
    playing: Boolean
  }

  mouseOver () {
    this.playVideo()
  }

  mouseOut () {
    this.pauseVideo()
  }

  fullScreen () {
    // User is requesting the video tag go full screen.
    if (this.hasVideoTarget) {
      // Now we tell our video tag to go full screen, hopefully it works!
      if (this.videoTarget.requestFullscreen) {
        this.videoTarget.requestFullscreen()
      } else if (this.videoTarget.mozRequestFullScreen) {
        this.videoTarget.mozRequestFullScreen()
      } else if (this.videoTarget.webkitRequestFullscreen) {
        this.videoTarget.webkitRequestFullscreen()
      } else if (this.videoTarget.msRequestFullscreen) {
        this.videoTarget.msRequestFullscreen()
      } else if (this.videoTarget.webkitEnterFullScreen) {
        this.videoTarget.webkitEnterFullScreen()
      }
    }
  }

  playVideo () {
    if (this.hasVideoTarget) {
      const videoPromise = this.videoTarget.play()
      if (typeof videoPromise !== 'undefined') {
        videoPromise.then(() => {
          if (!this.loadedValue) {
            this.loadedValue = true
            this.logVideoPreviewEvent()
          }
          this.playingValue = true
        }).catch(() => {})
      }
    }
  }

  pauseVideo () {
    if (this.hasVideoTarget && this.playingValue) {
      // Only pause if video is actually playing
      // Re: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      this.videoTarget.pause()
    }
    this.playingValue = false
  }

  logVideoPreviewEvent () {
    googleAnalytics3Event({
      eventAction: 'hover',
      // event category should be something like:
      // "Video Preview"
      // "videoTemplate/Premiere Pro Preview"
      eventCategory: `${this.itemTypeValue} Preview`,
      eventLabel: this.itemIdValue,
      nonInteraction: true
    })
  }
}
