import Cookies from 'js-cookie'
import { Controller } from 'stimulus'
import { Consent, deferRun, userHasOptedOutOfCookiesForCategory } from 'lib/consent'
import { getCookieDomains } from 'lib/getCookieDomains'
import { loadScript } from 'lib/load_script'

export default class extends Controller {
  static values = {
    cookiebotEnabled: Boolean,
    analyticsId: String,
    analyticsEnabled: Boolean
  }

  connect () {
    // We don't want to do this GTM loading when displaying the turbo in-memory cached version.
    // After a few more milliseconds the network request will finish,
    // and this connect() will run again without this preview attribute set.
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    // If we've disabled analytics for whatever reason (e.g. local dev) then we return early
    if (!this.analyticsEnabledValue) return

    const loadGTMScript = () => {
      loadScript(`https://www.googletagmanager.com/gtag/js?id=${this.analyticsIdValue}&l=ga3dataLayer`)
    }

    if (this.cookiebotEnabledValue) {
      // We use our handy deferRun() helper to dynamically load the GTM script.
      // This will load the GTM script straight away if we already have consent, or wait for consent before loading it.
      deferRun(loadGTMScript, Consent.statistics)
    } else {
      // Cookiebot is disabled, we just load the GTM script straight away:
      loadGTMScript()
    }

    if (typeof window !== 'undefined') {
      this.handleCookiebotAcceptDeclineEvent = this.handleCookiebotAcceptDeclineEvent.bind(this)
      window.addEventListener('CookiebotOnAccept', this.handleCookiebotAcceptDeclineEvent)
      window.addEventListener('CookiebotOnDecline', this.handleCookiebotAcceptDeclineEvent)
    }
  }

  // On declining statistics consent, we disable
  // tracking and delete GA-related storage
  handleCookiebotAcceptDeclineEvent () {
    if (userHasOptedOutOfCookiesForCategory(Consent.statistics)) {
      // Explicitly disable GA as per documentation as an extra precaution
      // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
      window[`ga-disable-${this.analyticsIdValue}`] = true

      // Manually delete any existing GA cookies
      ;['_ga', '_gid', '_gat'].forEach((cookieName) => {
        // Google sets some cookies on the full current domain and some on the apex domain
        // (e.g. some on www.example.com and some on example.com) so we loop through both to delete all cookies
        getCookieDomains().forEach((cookieDomain) => {
          Cookies.remove(cookieName, { path: '/', domain: cookieDomain })
        })
      })
    }
  }
}
