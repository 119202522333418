import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import { Consent, consented } from 'lib/consent'
import algoliaSearchInsights from 'search-insights'
import { localStorageSet, localStorageGet } from 'lib/localStorageWrapper'

const ALGOLIA_CLICKS_LOCAL_STORAGE_KEY = 'algolia-clicks'
const THIRTY_MINUTES_IN_MILLISECONDS = 1800000

export default class extends Controller {
  static values = {
    cookieName: String,
    userToken: String,
    indexName: String,
    queryId: String,
    appId: String,
    apiKey: String,
    items: Array
  }

  initialize () {
    // Little bind trick allowing our future window.addEventListener to function correctly
    this.handleCookiebotAcceptDeclineEventAlgoliaAnalytics = this.handleCookiebotAcceptDeclineEventAlgoliaAnalytics.bind(this)
  }

  connect () {
    // If for some reason we don't know what cookie name value to use, return early:
    if (!this.cookieNameValue) return

    // If the user has already set their consent preferences, we can start analytics work straight away.
    if (consented(Consent.statistics)) {
      this.initializeAlgoliaSearchAnalytics()
    }

    // Set up cookiebot event listeners for when the user modifies their consent options:
    window.addEventListener('CookiebotOnAccept', this.handleCookiebotAcceptDeclineEventAlgoliaAnalytics)
    window.addEventListener('CookiebotOnDecline', this.handleCookiebotAcceptDeclineEventAlgoliaAnalytics)
  }

  disconnect () {
    window.removeEventListener('CookiebotOnAccept', this.handleCookiebotAcceptDeclineEventAlgoliaAnalytics)
    window.removeEventListener('CookiebotOnDecline', this.handleCookiebotAcceptDeclineEventAlgoliaAnalytics)
  }

  setUserTokenCookie () {
    if (!this.userTokenValue) return // Nothing to do here if we don't have a value to store in a cookie.

    const existingCookieValue = this.getUserTokenCookie()

    // If the user already has a user token cookie that differs from the userTokenValue that was rendered on this page,
    // we want to maintain the existing cookie value and not overwrite it.
    if (existingCookieValue && existingCookieValue !== this.userTokenValue) return

    // If we're here then either there's no existing cookie, so we want to create one.
    // OR there is an existing cookie that matches this.userTokenValue, so we want to set it again to bump its expiry date.
    Cookies.set(this.cookieNameValue, this.userTokenValue, { secure: true, expires: 182 })
  }

  getUserTokenCookie () {
    if (consented(Consent.statistics)) {
      return Cookies.get(this.cookieNameValue)
    }
  }

  removeUserTokenCookie () {
    Cookies.remove(this.cookieNameValue)
  }

  initializeAlgoliaSearchAnalytics () {
    // If we've already initialized the Algolia library, don't do it a second time.
    if (this.algoliaAnalyticsInitialized) return

    this.setUserTokenCookie()
    const userToken = this.getUserTokenCookie()

    // By this point we should have stored the user's token in a cookie. If we can't read it at this point then either:
    // a) They've landed on an item page without performing a search, so they don't have a token we can track them with yet, or
    // b) Their browser settings/extensions are blocking us from reading/writing cookies.
    // Either way we can't do any tracking on this page, so we just return early.
    if (!userToken) return

    algoliaSearchInsights('init', {
      appId: this.appIdValue,
      apiKey: this.apiKeyValue,
      useCookie: false,
      userToken: userToken
    })

    this.algoliaAnalyticsInitialized = true
  }

  trackClick (event) {
    // If we haven't initialized our library yet it probably means the user has opted out via Cookiebot, so we can return early here.
    if (!this.algoliaAnalyticsInitialized) return

    // Ensure the user has actually clicked on a link before we send the event tracking
    const clickedLink = event.target.closest('a')
    if (!clickedLink) return

    const target = event.target.closest('[data-algolia-analytics-object-id]')
    if (!target) return

    const item = this.findItemByObjectId(target.dataset.algoliaAnalyticsObjectId)
    if (!item) return

    algoliaSearchInsights('clickedObjectIDsAfterSearch', {
      eventName: 'Grid click',
      index: this.indexNameValue,
      queryID: this.queryIdValue,
      objectIDs: [item.object_id],
      positions: [item.position]
    })
  }

  storeConversionData (event) {
    // If we haven't initialized our library yet it probably means the user has opted out via Cookiebot, so we can return early here.
    if (!this.algoliaAnalyticsInitialized) return

    // Ensure the user has actually clicked on a link before we send the event tracking
    const clickedLink = event.target.closest('a')
    if (!clickedLink) return

    const target = event.target.closest('[data-algolia-analytics-object-id]')
    if (!target) return

    const item = this.findItemByObjectId(target.dataset.algoliaAnalyticsObjectId)
    if (!item) return

    // We need the "Object ID", "Algolia Index" and "Search Query ID" for item page conversion tracking to work.
    // However we don't have this information on the item page, and the item page isn't powered by Algolia.
    // So the only time we have access to all this information is on the "previous" search result page, the one the user
    // clicks to visit the item page.
    // So when the user clicks on an item in search results, we store some analytics data in local storage that
    // is then accessible on the item page.
    // We use a basic local storage "key" that both algolia and item pages can compute: `itemType-itemId` example: `video-123`
    this.localStorageSetWrapperWithExpiry(this.localStorageKeyForItem(item), {
      queryId: this.queryIdValue,
      index: this.indexNameValue,
      objectId: item.object_id
    })
  }

  findItemByObjectId (objectId) {
    return this.itemsValue.find((item) => item.object_id === objectId)
  }

  localStorageGetWrapper (key) {
    const allLocalStorageRaw = localStorageGet(ALGOLIA_CLICKS_LOCAL_STORAGE_KEY)
    if (!allLocalStorageRaw) return

    const allLocalStorageData = JSON.parse(allLocalStorageRaw)
    if (!allLocalStorageData) return

    return allLocalStorageData[key]
  }

  localStorageSetWrapperWithExpiry (key, values) {
    let allLocalStorageRaw = localStorageGet(ALGOLIA_CLICKS_LOCAL_STORAGE_KEY)
    if (!allLocalStorageRaw) allLocalStorageRaw = '{}'

    let allLocalStorageData = JSON.parse(allLocalStorageRaw)
    if (!allLocalStorageData) allLocalStorageData = {}

    const timestampForThirtyMinutesAgo = Date.now() - THIRTY_MINUTES_IN_MILLISECONDS
    // first we remove any expired entries from this object, by looking at their "createdAt" data.
    Object.keys(allLocalStorageData).forEach(existingKey => {
      if (allLocalStorageData[existingKey].createdAt < timestampForThirtyMinutesAgo) {
        delete allLocalStorageData[existingKey]
      }
    })

    allLocalStorageData[key] = {
      createdAt: Date.now(),
      ...values
    }

    // Write this back to local storage.
    localStorageSet(ALGOLIA_CLICKS_LOCAL_STORAGE_KEY, JSON.stringify(allLocalStorageData))
  }

  localStorageKeyForItem (item) {
    // We build a local storage "key" based on the current item type and item id.
    // This lets us look up the entry by this key that we can compute from the item page.
    return `${item.item_type}-${item.item_id}`
  }

  localStorageKeyForElement (element) {
    // We build a local storage "key" based on the current item type and item id.
    // On the item page, this lets us check localStorage to see if we have any previously-stored data for this item.
    return `${element.dataset.algoliaAnalyticsItemType}-${element.dataset.algoliaAnalyticsItemId}`
  }

  trackConversion (event) {
    // If we haven't initialized our library yet it probably means the user has opted out via Cookiebot, so we can return early here.
    if (!this.algoliaAnalyticsInitialized) return

    const target = event.target.closest('[data-algolia-analytics-item-id]')
    if (!target) return

    const algoliaAnalyticsClickData = this.localStorageGetWrapper(this.localStorageKeyForElement(target))
    if (!algoliaAnalyticsClickData) return

    const { queryId, index, objectId } = algoliaAnalyticsClickData

    algoliaSearchInsights('convertedObjectIDsAfterSearch', {
      eventName: 'Item download',
      index: index,
      queryID: queryId,
      objectIDs: [objectId]
    })
  }

  // When the user has provided statistics consent we can start analytics work.
  // Or if they've declined, we delete their user token cookie.
  handleCookiebotAcceptDeclineEventAlgoliaAnalytics () {
    if (consented(Consent.statistics)) {
      this.initializeAlgoliaSearchAnalytics()
    } else {
      this.removeUserTokenCookie()
    }
  }
}
