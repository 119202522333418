const ORIGINAL_UTM_SOURCE_KEY = 'original-utm-source'

export function removeOriginalSource () {
  try {
    window.sessionStorage.removeItem(ORIGINAL_UTM_SOURCE_KEY)
  } catch (e) {
    return null
  }
}

export function storeOriginalSource (originalSource) {
  try {
    window.sessionStorage.setItem(ORIGINAL_UTM_SOURCE_KEY, originalSource)
  } catch (e) {
    return null
  }
}

export function retrieveOriginalSource () {
  try {
    return window.sessionStorage.getItem(ORIGINAL_UTM_SOURCE_KEY)
  } catch (e) {
    return null
  }
}
