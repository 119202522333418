import { Controller } from '@hotwired/stimulus'
import { handleError } from 'lib/errors'

// These are all the supported license types for modals
const allLicenseTypes = ['videoFree', 'videoRestricted', 'musicFree', 'sfxFree', 'videoTemplateFree', 'artFree']

export default class extends Controller {
  static values = {
    anchor: Boolean
  }

  getGlobalModalController () {
    return this.application.controllers.find(controller => controller.context.identifier === 'modal')
  }

  open (event) {
    event && event.preventDefault()
    // We need to get licenseType from `data-license="sfxFree"` prop on the card itself.
    // This is accessible via `event.currentTarget` which is the element the event listener was registered on.
    // So when clicking a child <h2> or <button> this still allows us to access the prop on the parent DOM node.
    const licenseType = event.currentTarget.dataset.license
    this.displayLicenseModal(licenseType)
    return false
  }

  displayLicenseModal (licenseType) {
    if (!allLicenseTypes.includes(licenseType)) {
      // invalid license type
      return
    }

    // Reach out to the existing other global modal controller
    const modalController = this.getGlobalModalController()

    // Render modal on the screen
    modalController.showModal()

    if (this.shouldWeUpdateModalUrlAnchor()) {
      // Update the current browser anchor with the license type
      window.location.hash = licenseType
    }

    // Fetch the modal content defined in the button data-attribute
    fetch(`/license/modal/${licenseType}/`)
      .then(
        // Extract the modal response message if we get a 200 success
        response => {
          if (response.ok) {
            return response.text()
          } else {
            const msg = `Status: ${response.status} while fetching modal for '${licenseType}'`
            throw new Error(msg)
          }
        })
      .then(
        // Populate our modal with the content from the API response
        html => modalController.setContentHtml(html))
      .catch(
        // If we get a 404 or the network request fails we log the error to rollbar and hide the modal
        error => {
          handleError(error)
          modalController.hideModal()
        })

    return false
  }

  openModalByAnchor () {
    if (this.shouldWeUpdateModalUrlAnchor() && window.location.hash.length > 0) {
      const licenseType = window.location.hash.substr(1)
      this.displayLicenseModal(licenseType)
    }
  }

  shouldWeUpdateModalUrlAnchor () {
    return this.anchorValue
  }

  // On the license page we'd like to listen for the modal closed event and remove the #anchor from the URL
  modalHasBeenClosed () {
    if (this.shouldWeUpdateModalUrlAnchor()) {
      // We have to use pushState() here because window.location.hash = '' leaves a '#' in the url and causes a scroll-up
      window.history.pushState({ turbolinks: {} }, document.title, window.location.pathname)
    }
  }

  close () {
    // Reach out to the existing other global modal controller
    const modalController = this.getGlobalModalController()

    // Close the modal
    modalController.close()
  }
}
