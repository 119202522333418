import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  getGlobalModalController () {
    return this.application.controllers.find(controller => controller.context.identifier === 'modal')
  }

  openFilterModal (event) {
    event.preventDefault()

    // Reach out to the existing other global modal controller
    const modalController = this.getGlobalModalController()

    // Render modal on the screen
    modalController.showModal()

    const facetSelector = document.getElementById('filter-modal-template').content.children[0].cloneNode(true)
    //
    modalController.setContentNode(facetSelector)

    return false
  }

  closeModal () {
    // Reach out to the existing other global modal controller
    const modalController = this.getGlobalModalController()

    // Hide our modal
    modalController.close()
  }
}
