import { Controller } from '@hotwired/stimulus'
import { Consent, deferRun } from 'lib/consent'
import { shouldDisplayElementsToastBanner, markElementsToastBannerDismissed } from 'lib/elementsToastBanner'

export default class extends Controller {
  static values = {
    visible: String
  }

  connect () {
    deferRun(() => { this._checkLocalStorageAndDisplayBanner() }, Consent.marketing)
  }

  dismiss () {
    deferRun(markElementsToastBannerDismissed, Consent.marketing)
    this.visibleValue = 'false'
  }

  _checkLocalStorageAndDisplayBanner () {
    if (shouldDisplayElementsToastBanner()) this._displayBanner()
  }

  _displayBanner () {
    this.visibleValue = 'true'
  }
}
