import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  openSettings () {
    if (window.Cookiebot) {
      window.Cookiebot.renew()
      window.addEventListener('CookiebotOnLoad', () => window.location.reload())
    }
  }
}
