// This file was copied from market-storefront: https://github.com/envato/market-storefront/blob/main/app/javascript/utils/load_script.js
export const loadScript = (src) => {
  const scriptTag = document.createElement('script')
  const firstScriptTag = document.getElementsByTagName('script')[0]
  scriptTag.async = true
  scriptTag.src = src
  if (firstScriptTag && firstScriptTag.parentNode) {
    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag)
  }
}
