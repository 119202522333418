import { Controller } from '@hotwired/stimulus'
import { standardisedClickTracking } from '@envato/gtm-analytics'
import { googleAnalytics3Event, googleAnalytics3, googleAnalytics3StandardisedClickTracking } from 'lib/googleAnalytics3'

export default class extends Controller {
  static values = {
    ga3TrackingEnabled: Boolean,
    ga3QueryParams: Array
  }

  connect () {
    if (this.ga3TrackingEnabledValue) {
      this.logGa3PageView()
      // Changing this "analyticsProcessed" data attribute causes the connect() to fire on each turbo page load
      this.element.dataset.analyticsProcessed = 'true'
    }
  }

  logGa3PageView () {
    // We only want to send certain query strings to Google Analytics (not the entire location.search string).
    // Right now we only want the ?page=X values to go through to GA:
    const allowedQueryParams = this.ga3QueryParamsValue

    // This loops over the available search params and builds a new list based on the "allowedQueryParams" above
    const browserSearchParams = new URLSearchParams(window.location.search)
    const searchParamsToLog = new URLSearchParams()
    for (const [searchParam, searchParamValue] of browserSearchParams) {
      if (allowedQueryParams.includes(searchParam)) {
        searchParamsToLog.append(searchParam, searchParamValue)
      }
    }

    // We then stringify any allowed search params and append that to our GA page
    const searchStringToLog = searchParamsToLog.toString()
    const pagePath = `${window.location.pathname}${searchStringToLog.length > 0 ? `?${searchStringToLog}` : ''}`

    googleAnalytics3('set', 'page_path', pagePath)
    googleAnalytics3Event({
      eventAction: 'page_view',
      eventCategory: null,
      eventLabel: null
    })
  }

  trackClick (event) {
    // GA4 standardised click tracking.
    standardisedClickTracking(event)

    // GA3 standardised click tracking.
    googleAnalytics3StandardisedClickTracking(event)
  }
}
