import { Controller } from '@hotwired/stimulus'
import { handleError } from 'lib/errors'

export default class extends Controller {
  static values = {
    modalUrl: String
  }

  getGlobalModalController () {
    return this.application.controllers.find(controller => controller.context.identifier === 'modal')
  }

  openDownloadModal (event) {
    event.preventDefault()

    // Reach out to the existing other global modal controller
    const modalController = this.getGlobalModalController()

    // Render modal on the screen
    modalController.showModal()

    // Fetch the modal content defined in the button data-attribute
    fetch(this.modalUrlValue)
      .then(
        // Extract the modal response message if we get a 200 success
        response => {
          if (response.ok) {
            return response.text()
          } else {
            const msg = `Status: ${response.status} while fetching '${this.modalUrlValue}'`
            throw new Error(msg)
          }
        })
      .then(
        // Populate our modal with the content from the API response
        html => modalController.setContentHtml(html))
      .catch(
        // If we get a 404 or the network request fails we log the error to rollbar and hide the modal
        error => {
          handleError(error)
          modalController.hideModal()
        })

    return false
  }
}
