import { Controller } from '@hotwired/stimulus'
import { sendAnalyticsEvent } from '@envato/gtm-analytics'

export default class extends Controller {
  static values = {
    items: Array
  }

  connect () {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    // On an item page, we should have a single item and we want to send a `view_item` event on page load.
    if (this.itemsValue.length === 1) {
      this.viewItem()
    }
  }

  viewItem () {
    sendAnalyticsEvent({
      eventName: 'view_item',
      ecommerce: {
        items: this.itemsValue
      }
    })
  }
}
