import { Controller } from 'stimulus'
const throttle = require('lodash/throttle')

// We use this to calculate the room required to fit the "..." link in
// needs to be size of button + any margins
const metaLinkMargin = 10
const moreDropdownSpacing = 32 + metaLinkMargin

export default class extends Controller {
  static targets = ['metaWrapper', 'link', 'more', 'moreLinks']
  static values = {
    showMore: Boolean
  }

  initialize () {
    this.layout = throttle(this.layout, 200)
  }

  connect () {
    // We don't want to do this expensive work on the turbo in-memory cached version.
    // We wait a few more milliseconds for the network request to load, then we do the real initialization.
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.layout()
  }

  layout () {
    // Everytime the browser resizes this function gets called, so we want
    // to reset all the things so on the last resize movement, so the
    // correct mutations are performed to the data and DOM.
    this.moreLinksTarget.innerHTML = ''
    this.showMoreValue = false
    const metaWrapperWidth = Math.floor(this.metaWrapperTarget.offsetWidth) || 0
    if (metaWrapperWidth <= 0) {
      return
    }
    const navChildren = this.linkTargets
    let visibleElementsWidth = 0
    const linksToShowInMore = []

    navChildren.forEach((child, index) => {
      child.removeAttribute('data-hidden')
      const bounding = child.getBoundingClientRect()
      visibleElementsWidth += bounding.width + metaLinkMargin

      if (visibleElementsWidth + moreDropdownSpacing > metaWrapperWidth) {
        // This nav drop down isn't going to fit on the page, so we mark is as hidden and move it into a "more" dropdown
        child.setAttribute('data-hidden', 'true')
        linksToShowInMore.push(child.cloneNode(true))
      }
    })
    linksToShowInMore.forEach(child => {
      this.moreLinksTarget.appendChild(child)
    })
    // We have some links in our more links div, lets show em.
    if (linksToShowInMore.length) {
      this.showMoreValue = true
    }
  }
}
