// This file was copied from market-storefront: https://github.com/envato/market-storefront/blob/main/app/javascript/utils/consent.js
import {
  Consent as packageConsent,
  consented as packageConsented,
  deferRun as packageDeferRun,
  userHasOptedOutOfCookiesForCategory as packageUserOptedOut
} from '@envato/cookie-consent'

const checkConsentTypePresent = (consentType) => {
  if (!consentType) {
    throw Error('Consent type unspecified')
  }
}
// Wrapping these allows for more readable mocks in tests
// Also allows us to make sure we are always passing consentType
export const Consent = packageConsent
export const consented = (consentType) => {
  checkConsentTypePresent(consentType)
  return packageConsented(consentType)
}
export const deferRun = (job, consentType) => {
  checkConsentTypePresent(consentType)
  return packageDeferRun(job, consentType)
}
export const userHasOptedOutOfCookiesForCategory = (consentType) => {
  checkConsentTypePresent(consentType)
  return packageUserOptedOut(consentType)
}
