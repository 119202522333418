import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'video',
    'progressBar',
    'progress',
    'progressTime',
    'durationTime'
  ]

  static values = {
    isPaused: Boolean,
    isMousedown: Boolean
  }

  connect () {
    this.isPausedValue = false
  }

  togglePlay () {
    this.isPausedValue ? this.videoTarget.play() : this.videoTarget.pause()
    this.isPausedValue = !this.isPausedValue
  }

  fullScreen () {
    // User is requesting the video tag go full screen.
    if (this.hasVideoTarget) {
      // Now we tell our video tag to go full screen, hopefully it works!
      if (this.videoTarget.requestFullscreen) {
        this.videoTarget.requestFullscreen()
      } else if (this.videoTarget.mozRequestFullScreen) {
        this.videoTarget.mozRequestFullScreen()
      } else if (this.videoTarget.webkitRequestFullscreen) {
        this.videoTarget.webkitRequestFullscreen()
      } else if (this.videoTarget.msRequestFullscreen) {
        this.videoTarget.msRequestFullscreen()
      } else if (this.videoTarget.webkitEnterFullScreen) {
        this.videoTarget.webkitEnterFullScreen()
      }
    }
  }

  setVideoDuration () {
    const videoDurationSeconds = Math.ceil(this.videoTarget.duration)
    const minutes = Math.floor(videoDurationSeconds / 60).toString()
    const seconds = (Math.ceil(videoDurationSeconds) - minutes * 60).toString()
    this.durationTimeTarget.textContent = minutes + ':' + seconds.padStart(2, '0')
  }

  progressUpdate () {
    // Progress bar
    const percent = this.videoTarget.currentTime / this.videoTarget.duration * 100
    this.progressBarTarget.style.flexBasis = `${percent}%`

    // Time played
    const minutes = Math.floor(this.videoTarget.currentTime / 60).toString()
    const seconds = (Math.ceil(this.videoTarget.currentTime) - minutes * 60).toString()
    this.progressTimeTarget.textContent = minutes + ':' + seconds.padStart(2, '0')
  }

  scrub (event) {
    const scrubTime = event.offsetX / this.progressTarget.offsetWidth * this.videoTarget.duration
    this.videoTarget.currentTime = scrubTime
  }

  mousemove (event) {
    this.isMousedownValue && this.scrub(event)
  }

  mousedown () {
    this.isMousedownValue = true
  }

  mouseup () {
    this.isMousedownValue = false
  }
}
