/* global ResizeObserver */

// This is some JS to help us with multi-line text truncation as seen in this demo:
// https://paulbakaus.com/tutorials/css/multiline-truncated-text-with-show-more-button-with-just-css/

import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['content']
  static classes = ['truncated', 'expanded']

  connect () {
    const wrapper = this.element

    if (typeof ResizeObserver === 'undefined') {
      // Some pre-2020 browsers don't support ResizeObserver, so we can't use this method to listen for the window being resized.
      // Instead, just expand the text to begin with and don't listen for resize events.
      wrapper.classList.add(this.expandedClass)
      return
    }

    this.observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        wrapper.classList[entry.target.scrollHeight > entry.contentRect.height ? 'add' : 'remove'](this.truncatedClass)
      }
    })

    this.observer.observe(this.contentTarget)
  }

  disconnect () {
    this.disconnectResizeObserver()
  }

  expand () {
    this.element.classList.add(this.expandedClass)

    // Once expanded there's no way to truncate the text again, so we can stop listening for resize events.
    this.disconnectResizeObserver()
  }

  disconnectResizeObserver () {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
