// Pass an Error object or a string message (or both) to log it to Rollbar.
// Any additional custom attributes to log can be passed as an object literal, and a callback function can be passed too.
// Arguments are documented here: https://docs.rollbar.com/docs/rollbarjs-configuration-reference#rollbarlog
// This will use severity level 'error' by default, but this can be overridden by specifying a `level` in the custom attributes object.
// e.g. `handleError('some message', { level: 'info', someOtherAttribute: 'foo' })
const handleError = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
  if (typeof window !== 'undefined' && typeof window.Rollbar !== 'undefined') {
    window.Rollbar.error(...args)
  }
}

// Logs a custom telemetry event to Rollbar, which will be visible on the Occurrence page for any individual error occurrence
// https://docs.rollbar.com/docs/rollbarjs-telemetry
// `metadata` must be an object with key/value pairs, passing a string will not work.
// Possible values for `level` are: 'debug', 'info', 'warning', 'error', 'critical'
const logTelemetryEvent = (metadata, level = 'info') => {
  if (typeof window !== 'undefined' && typeof window.Rollbar !== 'undefined') {
    window.Rollbar.captureEvent(metadata, level)
  }
}

export { handleError, logTelemetryEvent }
