import { Controller } from '@hotwired/stimulus'
import { Consent, consented } from 'lib/consent'
import { storeOriginalSource, removeOriginalSource, retrieveOriginalSource } from 'lib/originalSource'

// This controller captures the `utm_source` parameter when a user follows a link from a
// marketing channel, e.g. YouTube, TikTok etc.
//
// Since we don't have cross domain linking enabled we need to forward this parameter to track
// where traffic referred to Elements originated from.
//
// In order to achieve this we store the last `utm_source` seen in sessionStorage and
// dispatch an event for elements link controllers to add this value as the `utm_content`
// parameter.
//
// We use an event and dynamically update in order to honour CookieBot with respect to
// tracking marketing statistics via the sessionStorage key.
//
// Also because there is a potential race condition between this controller connecting and
// the elements links controllers connecting on the page we defer updating the links until
// the page has finished loading via the turbo:load event.
//
export default class extends Controller {
  initialize () {
    // Little bind trick allowing our future window.addEventListener to function correctly
    this.updateOriginalSourceTracking = this.updateOriginalSourceTracking.bind(this)
  }

  connect () {
    // Set up cookiebot event listeners for when the user modifies their consent options:
    window.addEventListener('CookiebotOnAccept', this.updateOriginalSourceTracking)
    window.addEventListener('CookiebotOnDecline', this.updateOriginalSourceTracking)
  }

  disconnect () {
    window.removeEventListener('CookiebotOnAccept', this.updateOriginalSourceTracking)
    window.removeEventListener('CookiebotOnDecline', this.updateOriginalSourceTracking)
  }

  trackOriginalSource () {
    const utmSource = new URL(window.location).searchParams.get('utm_source')
    if (utmSource) {
      storeOriginalSource(utmSource)
    } else if (document.referrer) {
      const referrerDomain = new URL(document.referrer).host
      storeOriginalSource(referrerDomain)
    }
  }

  stopTrackingOriginalSource () {
    removeOriginalSource()
  }

  // Raise global event allow any elements links to update
  dispatchUpdateOriginalSourceEvent () {
    const originalSource = retrieveOriginalSource()
    window.dispatchEvent(new CustomEvent('updateOriginalSource', { detail: { originalSource: originalSource } }))
  }

  // Called when any of the following events occur:
  //   - The document has finished loading, in the case consent has been given we need to dispatch immediately
  //   - CookieBot consent has been accepted
  //   - CookieBot consent has been declined
  updateOriginalSourceTracking () {
    if (consented(Consent.statistics)) {
      this.trackOriginalSource()
    } else {
      this.stopTrackingOriginalSource()
    }
    this.dispatchUpdateOriginalSourceEvent()
  }
}
