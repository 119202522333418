import { Controller } from '@hotwired/stimulus'
import { sendAnalyticsEvent } from '@envato/gtm-analytics'
import { userTextInputToUrlSlug, getItemSearchPath } from 'lib/linkHelpers'
import { googleAnalytics3Event } from 'lib/googleAnalytics3'

export default class extends Controller {
  static targets = ['input', 'headerLabel', 'headerOptions', 'autocompleteResults', 'autocompleteButton']
  static values = {
    itemType: String,
    autocomplete: Object,
    autocompleteIndex: Number,
    hasAutocompleteResults: Boolean,
    showClearButton: Boolean
  }

  connect () {
    this.cachedAutocompleteData = {}
    this.setHeaderLabel()
  }

  setHeaderLabel () {
    if (this.hasHeaderLabelTarget) {
      const searchItemType = this.itemTypeValue
      const currentElement = this.headerOptionsTarget.querySelector('.search-form__item-type-option--current')
      if (currentElement) {
        currentElement.classList.remove('search-form__item-type-option--current')
      }
      const newElementToHighlight = this.headerOptionsTarget.querySelector(`[data-type="${searchItemType}"]`)
      if (newElementToHighlight) {
        newElementToHighlight.classList.add('search-form__item-type-option--current')
        this.headerLabelTarget.innerText = newElementToHighlight.innerText
      }
    }
  }

  itemTypeChange (event) {
    event.preventDefault()
    const newItemType = event.target.getAttribute('data-type')
    this.itemTypeValue = newItemType
    this.inputTarget.value = ''
    this.setHeaderLabel()
    this.inputTarget.focus()
    // Close any previously open auto complete drop downs when changing item types
    this.autocompleteIndexValue = 0
    this.autocompleteResultsTarget.innerHTML = ''
    return false
  }

  logGaSearchEvent ({ searchTerm, searchType, callback }) {
    googleAnalytics3Event({
      eventAction: searchTerm,
      eventCategory: `Search Query (${searchType})`,
      eventLabel: this.itemTypeValue
    })

    sendAnalyticsEvent({
      eventName: 'search',
      context: this.getElementAnalyticsContext(this.element), // TODO: move this functionality into gtm-analytics package
      itemCategory: this.getGa4ItemCategory(this.itemTypeValue),
      searchType,
      searchTerm,
      callback
    })
  }

  getElementAnalyticsContext (element) {
    return element.closest('[data-analytics-context]')?.dataset.analyticsContext
  }

  getGa4ItemCategory (itemTypeValue) {
    // We need to convert 'item_type_software' to 'item type, software'. See specs for examples.
    if (itemTypeValue.startsWith('video_templates_')) {
      const commaIndex = 'video_templates'.length
      itemTypeValue = itemTypeValue.slice(0, commaIndex) + ',' + itemTypeValue.slice(commaIndex)
    }
    return itemTypeValue.replace(/_+/g, ' ')
  }

  async search (event) {
    event.preventDefault()
    if (this.isAutoCompleteItemType()) {
      // User is submitting the form on an autocomplete item type
      const searchMatches = await this.findAutoCompleteMatches()
      if (searchMatches.length > 0 && typeof searchMatches[this.autocompleteIndexValue] !== 'undefined') {
        // User has pressed enter with an auto complete search match highligted, we're going to search for that.
        this.redirectToAutoCompleteResult(searchMatches[this.autocompleteIndexValue].label, searchMatches[this.autocompleteIndexValue].url)
        return
      } else {
        // No matches found, so we're doing a normal search without auto completed option available.
        // This bubbles down to the normal getItemSearchPath() below
      }
    }

    const searchItemType = this.itemTypeValue
    const searchUrlSlug = userTextInputToUrlSlug(this.inputTarget.value)
    if (searchUrlSlug.length > 0) {
      const destinationUrl = getItemSearchPath({ itemType: searchItemType, searchTermUrlSlug: searchUrlSlug })
      this.logGaSearchEvent({
        searchTerm: this.inputTarget.value,
        searchType: 'free text',
        callback: () => {
          window.location.assign(destinationUrl)
        }
      })
    }
    return false
  }

  clearSearchTerms () {
    this.inputTarget.value = ''
    this.showClearButtonValue = false
  }

  isAutoCompleteItemType () {
    return typeof this.autocompleteValue[this.itemTypeValue] !== 'undefined'
  }

  async handleAutocomplete (event) {
    let keyCode

    if (event.key !== undefined) {
      keyCode = event.key
    } else if (event.keyIdentifier !== undefined) {
      keyCode = event.keyIdentifier
    } else if (event.keyCode !== undefined) {
      keyCode = event.keyCode
    }

    if (this.isAutoCompleteItemType()) {
      const searchMatches = await this.findAutoCompleteMatches()

      // For auto complete item types, we want to capture when user presses up or down, change which option is highlighted
      if (keyCode === 40 || keyCode === 38 || keyCode === 'ArrowDown' || keyCode === 'ArrowUp') {
        event.preventDefault()
        if (keyCode === 40 || keyCode === 'ArrowDown') {
          // User pressed down arrow.
          if (this.autocompleteIndexValue < searchMatches.length - 1) {
            this.autocompleteIndexValue++
          }
        }
        if (keyCode === 38 || keyCode === 'ArrowUp') {
          // User pressed up arrow.
          if (this.autocompleteIndexValue > 0) {
            this.autocompleteIndexValue--
          }
        }
      } else if (keyCode === 13 || keyCode === 'Enter') {
        // User pressed enter, this is handled by a normal <form> submit above
      } else {
        // Another type of keypress, we reset our autocomplete index to 0 & reset the autocomplete results HTML
        this.autocompleteIndexValue = 0
        this.autocompleteResultsTarget.innerHTML = ''
      }

      // Add data attribute to dom so we can hide/show the results dropdown nicely
      this.hasAutocompleteResultsValue = searchMatches.length > 0

      // Now render our search results on the page
      searchMatches.forEach((result, index) => {
        const existingAutocompleteResultButton = document.querySelector(`[data-url='${result.url}']`)

        if (existingAutocompleteResultButton) {
          // This search match is already rendered, so just update the class
          if (index === this.autocompleteIndexValue) {
            existingAutocompleteResultButton.classList.add('search-form__autocomplete-result--current')
          } else {
            existingAutocompleteResultButton.classList.remove('search-form__autocomplete-result--current')
          }
        } else {
          // This search match isn't yet rendered, so construct from the template
          const autocompleteResultButton = this.autocompleteButtonTarget.content.children[0].cloneNode()
          autocompleteResultButton.innerHTML = `${result.label.slice(0, result.resultIndexStart)}<strong>${result.label.slice(result.resultIndexStart, result.resultIndexEnd)}</strong>${result.label.slice(result.resultIndexEnd)}`
          autocompleteResultButton.dataset.label = result.label
          autocompleteResultButton.dataset.url = result.url
          autocompleteResultButton.dataset.index = index
          if (index === this.autocompleteIndexValue) {
            autocompleteResultButton.classList.add('search-form__autocomplete-result--current')
          }
          this.autocompleteResultsTarget.appendChild(autocompleteResultButton)
        }
      })
    }
  }

  async getAutocompleteData (itemType) {
    if (typeof this.cachedAutocompleteData[itemType] !== 'undefined') {
      // We're already cached! return it
      return this.cachedAutocompleteData[itemType]
    }
    // Not cached yet, fetch + cache + return.
    const response = await fetch(this.autocompleteValue[itemType])
    const data = await response.json()
    this.cachedAutocompleteData[itemType] = data
    return this.cachedAutocompleteData[itemType]
  }

  async findAutoCompleteMatches () {
    if (this.inputTarget.value.length === 0) return []

    const dataSource = await this.getAutocompleteData(this.itemTypeValue)
    const searchQueryBits = userTextInputToUrlSlug(this.inputTarget.value).split('-')
    return dataSource.reduce((acc, item) => {
      let weFoundAPartialMatch = false // This is a flag to figure out if the current search word has matched or not
      searchQueryBits.forEach(searchQueryBit => {
        if (!weFoundAPartialMatch) {
          const resultIndex = item.label.toLowerCase().indexOf(searchQueryBit)
          if (resultIndex !== -1) {
            item.resultIndexStart = resultIndex
            item.resultIndexEnd = resultIndex + searchQueryBit.length
            acc.push(item)
            weFoundAPartialMatch = true
          }
        }
      })
      return acc
    }, [])
      .sort((a, b) => a.label.length - b.label.length)
      .slice(0, 10)
  }

  clickAutocompleteResult (event) {
    event.preventDefault()
    this.redirectToAutoCompleteResult(event.currentTarget.dataset.label, event.currentTarget.dataset.url)
    return false
  }

  redirectToAutoCompleteResult (label, url) {
    this.logGaSearchEvent({
      searchTerm: label,
      searchType: 'autocomplete',
      callback: () => {
        window.location.assign(url)
      }
    })
  }

  hoverAutocompleteResult (event) {
    this.autocompleteIndexValue = event.target.dataset.index

    for (const result of this.autocompleteResultsTarget.children) {
      result.classList.remove('search-form__autocomplete-result--current')
    }

    event.target.classList.add('search-form__autocomplete-result--current')
  }
}
