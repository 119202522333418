import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    menuOpen: Boolean
  }

  static classes = ['search']

  initialize () {
    // We don't want to setup any event listeners during the turbo in memory cache loading time.
    // We wait a few more milliseconds for the network request to load, then we allow this block of code to run.
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    // Turbo caches our pages in browser memory, and briefly displays them to the user when re-visiting a cached page.
    // On a mobile device, this has an odd side effect of the mobile nav showing as the old open cached state.
    document.addEventListener('turbo:before-cache', () => {
      this.menuOpenValue = false
    }, { once: true })

    window.addEventListener('resize', this.handleWindowResize)
  }

  dynamicSearchScroll () {
    if (window.scrollY > 210) {
      this.element.classList.add(this.searchClass)
    } else {
      this.element.classList.remove(this.searchClass)
    }
  }

  toggleMobileMenu (event) {
    event.preventDefault()
    this.menuOpenValue = !this.menuOpenValue
    return false
  }

  handleWindowResize = () => {
    if (window.innerWidth >= 1000) {
      this.menuOpenValue = false
    }
  };
}
