/* global Event */

import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['container', 'content']
  static values = {
    opened: Boolean,
    loading: Boolean
  }

  initialize () {
    // We don't want to setup any event listeners during the turbo in memory cache loading time.
    // We wait a few more milliseconds for the network request to load, then we allow this block of code to run.
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    // Turbo caches our pages in browser memory, and briefly displays them to the user when re-visiting a cached page.
    // On a mobile device, this has an odd side effect of the mobile nav showing as the old open cached state.
    document.addEventListener('turbo:before-cache', () => {
      this.openedValue = false
    }, { once: true })
  }

  close () {
    this.hideModal()
    this.emptyModalContents()
  }

  showModal () {
    this.showLoadingSpinner()
    this.openedValue = true
  }

  hideModal () {
    this.hideLoadingSpinner()
    this.openedValue = false
    // This allows other components to "listen" for when the modal is closed.
    // We use this for (e.g.) updating the #hash link when a license page modal is closed.
    window.dispatchEvent(new Event('modalClosed'))
  }

  showLoadingSpinner () {
    this.loadingValue = true
  }

  hideLoadingSpinner () {
    this.loadingValue = false
  }

  emptyModalContents () {
    this.setContentHtml('')
  }

  setContentHtml (html) {
    this.hideLoadingSpinner()
    this.contentTarget.innerHTML = html
  }

  setContentNode (node) {
    this.hideLoadingSpinner()
    this.emptyModalContents()
    this.contentTarget.appendChild(node)
  }
}
