import { Controller } from 'stimulus'
const throttle = require('lodash/throttle')

const moreDropdownSpacing = 150
// Copied from SCSS mixin value: $desktopWidth: 1000px;
const desktopBreakpoint = 1000

const getColumnCount = (itemCount) => {
  if (itemCount > 20) return 3
  if (itemCount > 8) return 2
  if (itemCount > 0) return 1
  return 0 // We use column-count="0" as a special case to hide the dynamic "more" drop down listing
}

export default class extends Controller {
    static targets = ['nav', 'link', 'more', 'moreLinks', 'template']

    initialize () {
      this.layout = throttle(this.layout, 200)
    }

    getGlobalModalController () {
      return this.application.controllers.find(controller => controller.context.identifier === 'modal')
    }

    // This is fired from the DOM: data-action="click->global--navigation#openModal"
    // On mobile screens we want to open a modal and populate it with content.
    openModal (event) {
      if (window.innerWidth >= desktopBreakpoint) {
        return
      }
      event.preventDefault()
      // Reach out to the existing other global modal controller
      const modalController = this.getGlobalModalController()

      // Create the modal content wrapping element:
      const modalWrapper = this.templateTarget.content.children[0].cloneNode(true)

      // Add all the child links to the modal:
      event.target.parentNode.querySelectorAll('a').forEach(childToClone => {
        if (!childToClone.href.match(/#$/)) { // Stop "More Videos" link from appearing in modal, as it's not clickable
          modalWrapper.appendChild(childToClone.cloneNode(true))
        }
      })

      // Close button:
      const modalCloseButton = modalWrapper.getElementsByClassName('global-navigation__modal-close-button')[0]
      const closeModal = this.closeModal.bind(this)
      modalCloseButton.addEventListener('click', closeModal)

      // Render modal on the screen
      modalController.showModal()

      // Display the content in the modal:
      modalController.setContentNode(modalWrapper)
      return false
    }

    closeModal () {
      const modalController = this.getGlobalModalController()
      modalController.close()
    }

    layout () {
      const containerWidth = Math.floor(this.navTarget.offsetWidth) || 0
      if (containerWidth <= 0) {
        return
      }
      const navChildren = this.linkTargets
      let visibleElementsWidth = 0
      const linksToShowInMore = []
      // Make all children visible, so our calculations below work:
      navChildren.forEach(child => {
        child.removeAttribute('data-hidden')
      })
      // Figure out which child elements to hide, but only when in desktop mode.
      if (containerWidth >= desktopBreakpoint) {
        navChildren.forEach((child, index) => {
          child.removeAttribute('data-hidden')
          child.removeAttribute('data-dropdown-direction')
          const bounding = child.getBoundingClientRect()
          visibleElementsWidth += bounding.width
          if (visibleElementsWidth + moreDropdownSpacing > containerWidth) {
            // This nav drop down isn't going to fit on the page, so we mark is as hidden and move it into a "more" dropdown
            child.setAttribute('data-hidden', 'true')
            child.querySelectorAll('a').forEach(childToClone => {
              if (!childToClone.href.match(/#$/)) { // Stop "More Videos" link from appearing in drop down, as it's not clickable
                linksToShowInMore.push(childToClone.cloneNode(true))
              }
            })
          } else {
            // Figure out if this dropdown is going to be too close to right edge, in which case we need to make it open to the left
            if (bounding.left + 500 > window.innerWidth) {
              child.setAttribute('data-dropdown-direction', 'left')
            }

            child.setAttribute('data-columns', getColumnCount(child.querySelectorAll('a').length))
          }
        })
      }

      this.moreTarget.setAttribute('data-columns', getColumnCount(linksToShowInMore.length))
      this.moreLinksTarget.innerHTML = ''
      linksToShowInMore.forEach(child => {
        this.moreLinksTarget.appendChild(child)
      })
    }

    connect () {
      // We don't want to do this expensive work on the turbo in-memory cached version.
      // We wait a few more milliseconds for the network request to load, then we do the real initialization.
      if (document.documentElement.hasAttribute('data-turbo-preview')) return

      this.layout()
    }
}
