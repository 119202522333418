// Wrappers around localStorage access with built-in error handling.
// Sometimes we cannot access localStorage due to the user's browser privacy
// settings, which is their right! There's no point reporting those errors to
// Rollbar since there's nothing we can do about that, so we catch those errors
// and return safely here.
// For this reason, we should never rely on localStorage being available.

export const localStorageSet = (key, value) => {
  try {
    window.localStorage.setItem(key, value)
  } catch (e) {
    // No access to localStorage. Silence this error and return safely.
  }
}

export const localStorageRemove = (key) => {
  try {
    window.localStorage.removeItem(key)
  } catch (e) {
    // No access to localStorage. Silence this error and return safely.
  }
}

export const localStorageGet = (key, defaultValue = null) => {
  try {
    const value = window.localStorage.getItem(key)

    // If the key is not present in localStorage, the browser will return `null`
    if (value === null) return defaultValue

    return value
  } catch (e) {
    // No access to localStorage. Silence this error and safely return the default value.
    return defaultValue
  }
}
