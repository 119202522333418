import { handleError } from 'lib/errors'

const googleAnalytics3 = (...args) => {
  // We don't want to send any analytics events during the turbo in memory cache loading time.
  // We wait a few more milliseconds for the network request to load, then we allow events to fire.
  if (document.documentElement.hasAttribute('data-turbo-preview')) return

  if (typeof window !== 'undefined' && typeof window.gtag3 === 'function') {
    window.gtag3(...args)
  }
}

const googleAnalytics3Event = ({ eventAction, eventCategory, eventLabel, eventValue = null, nonInteraction = false, callback = null }) => {
  // Firefox and ad blockers will prevent gtag from running at all.
  // So we register a callback that is fired after 500ms in case gtag doesn't run at all.
  const eventCallbackFallback = callback ? setTimeout(callback, 500) : null

  // Extract the current pageType and itemType from the <body> tag, append it to every GA event we send:
  const pageType = document.body.dataset.analyticsPageType
  const itemType = document.body.dataset.analyticsItemType

  // Push the GA event into Google Tag Manager:
  googleAnalytics3(
    'event',
    eventAction,
    {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
      non_interaction: nonInteraction,
      event_callback: (containerId) => {
        // This callback is fired for every "tag" that is registered in Google Tag Manager.
        // e.g. once for Analytics, and once for Optimize.
        // We have to check the container ID starts with "UA-" to know it's a Google Analytics callback.
        if (containerId?.startsWith('UA-')) {
          // This fires when the GA event has successfully been recorded.
          // We remove our fallback timer and call the callback function if one exists
          clearTimeout(eventCallbackFallback)
          callback && callback()
        }
      },
      // see "custom_map" in gtag_config for these custom dimension mappings:
      page_type: pageType,
      item_type: itemType
    }
  )
}

// Standardised Google Analytics helpers https://github.com/envato/standard-analytics
// This function takes a click event and determines whether we should log an event to GA3.
// We look for a "section id" and an "element id" and use that to build the event payload.
const googleAnalytics3StandardisedClickTracking = (event, submitEventFunction = null) => {
  const target = event.target.closest('[data-analytics-element-id]')
  if (!target) return

  const elementId = target.dataset.analyticsElementId
  const elementLabel = target.dataset.analyticsElementLabel
  const section = target.closest('[data-analytics-context], [data-analytics-section-id]')
  if (!section) {
    handleError('Missing data-analytics-context or data-analytics-section-id for standard analytics target', { elementId, elementLabel })
    return
  }

  const sectionId = section.dataset.analyticsContext || section.dataset.analyticsSectionId
  const sectionLabel = section.dataset.analyticsSectionLabel
  const tagName = target.nodeName?.toLowerCase()

  if (sectionId && elementId && tagName) {
    const submitEvent = typeof submitEventFunction === 'function' ? submitEventFunction : googleAnalytics3StandardEvent

    submitEvent({
      eventType: 'click',
      sectionId,
      sectionLabel,
      elementId,
      elementLabel,
      tagName
    })
  }
}

// This function takes all of the data gathered by `googleAnalytics3StandardisedClickTracking()`
// and builds it into our expected format for a GA3 event.
const googleAnalytics3StandardEvent = ({
  elementId,
  elementLabel,
  elementPosition,
  eventType,
  pageType,
  itemType,
  sectionId,
  sectionLabel,
  tagName
}) => {
  googleAnalytics3Event({
    eventCategory: [sectionId, sectionLabel].filter(Boolean).join(';'),
    eventAction: [eventType, tagName].filter(Boolean).join(';'),
    eventLabel: [elementId, elementLabel].filter(Boolean).join(';'),
    eventValue: elementPosition,
    pageType: pageType,
    itemType: itemType
  })
}

export { googleAnalytics3, googleAnalytics3Event, googleAnalytics3StandardisedClickTracking, googleAnalytics3StandardEvent }
